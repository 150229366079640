import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {TextField,Typography,Button, FormControl,Select,Switch,FormControlLabel} from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';

import classNames from 'classnames';
import Utils from '../api/api';
import theme,{styles} from '../layout/theme';


class AccountDetail extends Component {
  constructor(props){
    super(props);
    this.state = {
      emailTemplate: '',
      companies: [],
      account: {
        fullname:'',
        facebook:'',
        avatar: '',
        google: '',
        email: '',
        phone:'',
        birthday:'',
        lastLogin: '',
        isEmployer: false,
        isStudent: false,
        isCandidate: false,
        company: '',
        dateCreated: null,
        dateModified: new Date(),
        address: '',
        favourite: null,
        job: '',
        sex: 2,
        workplace: ''
      },
      error: {
        fullname_error:false,
        email_error:false,
        tel_error:false,
        error:false,
      },
      failed:false,
      message:'',
      isUpdate: false
    };
  }

  componentDidMount() {
    //console.log(this.props.action)
    var $this = this;
    var params = this.context.router.route.match.params;

    Utils.getListData("company", 1, 100, "0,0", null, 1, function(data) {
      //console.log(data);
      if (data.status === "success") {
        $this.setState({ companies: data.results });
      }
    });

    Utils.getData('blockcontent/url','partner_sendemail_registersuccessfully',function(res){
      if (res.status === 'success' && res.results.length) {
        $this.setState({emailTemplate: res.results[0]['detailVN']});
      }
    });

    if (params.id !== undefined) {
      this.setState({isUpdate:true},function(){
        Utils.getSingleData('accounts', params.id, (res) => {
          if (res.status === "success" && res.results !== undefined) {
            const data = {},
                item = res.results;
            for (var property in item) {
              if (item.hasOwnProperty(property)) {
                data[property] = item[property];
              }
            }
            data._id = item._id;
            if (data.isEmployer === undefined) data.isEmployer = false;
            if (data.isStudent === undefined) data.isStudent = false;
            if (data.company === undefined) data.company = '';
            if (data.dateCreated === undefined) data.dateCreated = new Date();
            if (data.dateModified === undefined) data.dateModified = new Date();
            $this.setState({ account: data });
          }
        });
      });
    }
  }

  handleFieldChange = (field) => (e) => {
    const $this = this,
          account = $this.state.account;

    if (e.target.type === 'checkbox') {
      account[e.target.id] = e.target.checked;
    } else if (field !== undefined) {
        account[field] = e.target.value;
    } else {
      account[e.target.id] = e.target.value;
      if (e.target.type === "textarea") {
        account[e.target.id] = e.target.value.substring(0, 200);
      }
    }

    if (account.company !=='' && (account.isEmployer === 'false' || account.isEmployer === false))
    this.setState({ account: account });
  }

  handleValidate = (e) => {
    const $this = this,
          account = $this.state.account,
          obj = $this.state.error;
    const p = e.target.id + '_error';

    account[e.target.id] = e.target.value;
    
    if (e.target.value.trim() === '' || e.target.value === null) {
      obj[p] = true;
    } else {
      obj[p] = false;
    }

    if (!obj.fullname_error && !obj.email_error && !obj.tel_error) {
      obj.error = false;
    } else {
      obj.error = true;
    }

    $this.setState({error: obj});
  }

  _save() {
    const $this = this;
    const data = $this.state.account;
    data.password = Utils.generatePassword();
    if (this.state.isUpdate) {
      //data._id = this.state.id;
      Utils._update("account", data, function(res) {
        //console.log(res);
        if (res.status === "success") {
          $this.context.router.history.push("/account/");
        } else {
          $this.setState({
            failed: true,
            message: JSON.stringify(res.message)
          });
        }
      });
    } else {
      Utils._add("account", data, function(res) {
        if (res.status === "success") {
          //$this.context.router.history.push("/account/");
          if(data.isEmployer) {
            var mail_account = {};
            mail_account.email = data.email;
            mail_account.emailsubject = 'Code BACs | Register Successfully';
            mail_account.fullname = data.fullname;
            mail_account.emailbody = 'Hi ' + data.fullname + '\n' + 'Account dành cho công ty ' + $this.state.companies.map(x=>x._id===data.company?x.name:'') + ' đã được tạo thành công với mật khẩu: ' + data.password;
            mail_account.sendto = data.email;

            if($this.state.emailTemplate!==''){
              mail_account.emailbody = Utils.processTemplate($this.state.emailTemplate,{
                account: {
                  fullname: data.fullname,
                  phone: data.tel,
                  email: data.email,
                  company: $this.state.companies.map(x=>x._id===data.company?x.name:''),
                  password: data.password
                }
              });
            }

            Utils.sendMail(mail_account,function(res){
              //console.log(res);
              $this.context.router.history.push("/account/");
            });
          } else {
            $this.context.router.history.push("/account/");
          }

        } else {
          $this.setState({
            failed: true,
            message: JSON.stringify(res.message)
          });
        }
      });
    }
  }

  handleSave = (e) => {
    const params = this.context.router.route.match.params;
    //console.log(params);
    if (params.id !== undefined && !this.state.isUpdate) {
      this.setState({isUpdate:true});
    }

    const $this = this,
          obj = $this.state.error,
          account = this.state.account;
          
    if (!obj.error) {
      if (account.email === '') obj.email_error = true;
      if (account.tel === '') obj.tel_error = true;
      if (account.fullname === '') obj.fullname_error = true;
      
      obj.error = obj.email_error || obj.fullname_error || obj.tel_error;

      $this.setState({ error: obj }, () => {
        if (!$this.state.error.error) $this._save();
      });
    }
  }

  handleCancel = () => {
    this.context.router.history.push('/account/');
  }

  render(){
    const classes = this.props.classes;
    const account = this.state.account;
    const error = this.state.error;

    return(
      <div className="page-detail">
        <form className={classes.form} noValidate>
          <Typography type="body2" className={classNames(classes.error, !error.error && classes.hide)}>Vui lòng nhập tất cả các field (*)</Typography>
          <Typography type="body2" className={classNames(classes.error, !this.state.failed && classes.hide)}>{this.state.message}</Typography>
          <TextField id="fullname" label="Họ và tên" className={classes.textField} value={account.fullname} onChange={(event) => this.handleValidate(event)} onBlur={(event) => this.handleValidate(event)} margin="normal" required={true} error={error.fullname_error}
          />
          <TextField
            id="email"
            label="Email"
            className={classes.textField}
            margin="normal"
            type="email"
            value={account.email}
            onChange={this.handleValidate}
            onBlur={this.handleValidate}
            required={true}
            error={this.state.email_error}
          />
          <FormGroup row className={classes.formRow}>
            <TextField
              id="tel"
              label="Số điện thoại"
              type="tel"
              className={classes.textFieldInline}
              margin="normal"
              value={account.phone}
              onChange={this.handleValidate}
              onBlur={this.handleValidate}
              required={true}
              error={error.tel_error}
            />
            <TextField
              id="birthday"
              label="Ngày sinh"
              className={classes.textFieldInline}
              margin="normal"
              type="date"
              value={account.birthday}
              onChange={this.handleFieldChange('birthday')}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormGroup>
          <FormGroup className={classes.formRowInline}>
            <FormControl className={classes.col6}>
              <FormControlLabel
                control={
                  <Switch
                    id="isEmployer"
                    checked={account.isEmployer.toString()==='true'}
                    onChange={this.handleFieldChange('isEmployer')}
                  />
                }
                label="Là nhà tuyển dụng"
              />
              <FormControlLabel
                control={
                  <Switch
                    id="isStudent"
                    checked={account.isStudent.toString()==='true'}
                    onChange={this.handleFieldChange('isStudent')}
                  />
                }
                label="Là học viên"
              />
            </FormControl>
            <FormControl className={classes.col6}>
              <Typography className={classes.label} type="caption">Công ty</Typography>
              <Select
                disabled={!account.isEmployer}
                native
                id="company"
                value={account.company}
                onChange={this.handleFieldChange('company')}
              >
                <option key={0} value="">
                  ...
                </option>
                {this.state.companies.map((item, index) => (
                  <option key={index + 1} value={item._id}>
                    {item.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </FormGroup>
          <FormGroup row className={classes.formRow}>
            <Button color="default" onClick={this.handleCancel}>
              Quay lại
            </Button>
            <Button variant="contained" color="primary" onClick={this.handleSave}>
              Lưu
            </Button>
          </FormGroup>
        </form>
      </div>
    )
  }
}

AccountDetail.propTypes = {
  classes: PropTypes.object.isRequired,
};
AccountDetail.contextTypes = {
  router: PropTypes.object
};
export default withStyles(styles)(AccountDetail);
