import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Fab, Toolbar, TextField, Switch, FormControlLabel, FormControl} from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import Paper from '@material-ui/core/Paper';
import classNames from 'classnames';
import ReactTable from './shares/react_table';

import theme, {styles} from '../layout/theme';
import Utils from '../api/api';


export class AccountList extends Component {
  constructor(props){
    super(props);
    this.state = {
      keyword: '',
      isEmployer: false,
      isStudent: false,
      isCandidate: false,
      searchClick: false,
      filter: ''
    };
  }

  handleSearch(e){var el = document.getElementById('keyword');
    this.setState({ keyword: el.value.trim() });
  }

  handleAdd(){
    this.context.router.history.push('/account/add');
  }

  // handleBillFilter(obj){
  //   this.context.router.history.push('/bill/filter/'+obj._id);
  // }

  filterData = (e) => {
    const $this = this,
          state = $this.state;

    //state[e.target.id] = e.target.value.trim();
    state[e.target.id] = e.target.checked;

    $this.setState(state, function(){
      $this.handleFilter();
    });
  }

  handleFilter() {
    let f = this.state.filter;

    if (f === '') {
      f = {};
    } else {
      f = JSON.parse(f);
    }

    if (this.state.isEmployer) {
      f.isEmployer = this.state.isEmployer.toString();
    } else if (f.isEmployer !== undefined) {
      delete f.isEmployer;
    }

    if (this.state.isStudent) {
      f.isStudent = this.state.isStudent.toString();
    } else if (f.isStudent !== undefined) {
      delete f.isStudent;
    }

    if (this.state.isCandidate) {
      f.isCandidate = this.state.isCandidate.toString();
    } else if (f.isCandidate !== undefined) {
      delete f.isCandidate;
    }

    this.setState({filter: JSON.stringify(f)});
  }
  render() {
    const classes = this.props.classes;
    const $this = this;
    var source = {
      thead: [{title:'Tên',value:'name'}, { title: 'Ngày sinh', value: 'birthday' },{title:'SĐT',value:'phone'},{title:'Facebook',value:'fb'},{title:'Google/Email',value:'google'}],
      tbody: function (n) {
        return ({
          _id: n._id,
          name: n.fullname,
          birthday: n.birthday ? Utils.getBeautyDate(n.birthday) : '_________',
          phone: n.tel,
          fb: n.facebook,
          google: (<div><p>{n.google}</p><p><a target='_blank' href={'mailto:'+n.email}>{n.email}</a></p></div>),
        });
      }
    };
    return(
      <div className="list-page">
        <div className={classNames(classes.auto_toggle, this.state.failed && classes.run)}>
          <Typography type="body2">{this.state.message}</Typography>
        </div>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.heading} variant="h5">Danh mục tài khoản</Typography>
        </Toolbar>
        <Paper className={classes.paper}>
          <Toolbar className={classes.tableToolbar}>
          <FormGroup className={classes.formRowToolbar}>
              <FormGroup className={classes.formRowInline}>
                <FormControl className={classes.col3}>
                  <FormControlLabel
                    control={
                      <Switch
                        id="isStudent"
                        checked={$this.state.isStudent}
                        onChange={$this.filterData}
                      />
                    }
                    label="Học viên"
                  />
                </FormControl>
                <FormControl className={classes.col3}>
                  <FormControlLabel
                    control={
                      <Switch
                        id="isEmployer"
                        checked={$this.state.isEmployer}
                        onChange={$this.filterData}
                      />
                    }
                    label="Đối tác"
                  />
                </FormControl>
                <FormControl className={classes.col3}>
                  <FormControlLabel
                    control={
                      <Switch
                        id="isCandidate"
                        checked={$this.state.isCandidate}
                        onChange={$this.filterData}
                      />
                    }
                    label="Ứng viên"
                  />
                </FormControl>
              </FormGroup>
              <FormGroup className={classes.formRow}>
                <TextField type="search" id="keyword" label="Tìm kiếm" className={classNames(classes.textField + ' form-control-no-bottom')} value={this.state.keyword} onChange={(event)=>this.setState({keyword:event.target.value.trim()})}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true
                  }}/>
              </FormGroup>
            </FormGroup>
            <Fab color="primary" aria-label="Tìm kiếm" className={classes.button} onClick={(e)=>this.handleSearch(e)}>
              <SearchIcon />
            </Fab>
            <Fab color="primary" aria-label="add" className={classes.button} onClick={()=>this.handleAdd()}>
              <AddIcon />
            </Fab>
          </Toolbar>
          <ReactTable apiFilter={this.state.filter} apiList='accounts' apiSingle='account' apiTable='accounts' router={this.context.router} dataMap={source} keyword={this.state.keyword} isSearch={this.state.searchClick} >
          </ReactTable>
        </Paper>
      </div>
    )
  }
}

AccountList.propTypes = {
  classes: PropTypes.object.isRequired,
};
AccountList.contextTypes = {
  router: PropTypes.object
};

export default withStyles(styles)(AccountList);
